import { config } from "../config";

export const URL = config.API_URL;
export const BASE_URL = URL + "/api";
export const API_URL = {
  CATEGORY_URL: "/Customer/GetCategoriesByRouteUrl",
  PRODUCT_URL: "/Product/GetProductFilter",
  SIZE_URL: "/Size",
  TEXTURE_URL: "/Texture/GetTextureByCustomerRoute",
  PRODUCT_DETAILS_URL: "/Product/GetProductByProductId",
  TESTIMONIAL_URL: "/Testimonial",
  CONTACT_URL: "/ContactUs/AddContact",
  PRODUCT_DETAILS_BYCODE_URL: "/Product/GetProductByProductCodeName",
  MAIL_URL:"/ContactUs/ContactMail"
};