import { GET_SIZE_SUCCESS } from "../constants";

const initialState = {
  size: [],
};

export default function sizeReducer(state = initialState, action) {
  switch (action.type) {
    case GET_SIZE_SUCCESS:
      console.log("action.payload.data", action.payload.data);
      const size = action.payload.data;

      return {
        ...state,
        size: [...size],
      };

    default:
      return state;
  }
}
