import React from "react";
import FooterClient from "../footerClient";
import HeaderClient from "../headerClient";
import ScrollButton from "../scrollButton";

function PageClient({ children }) {
  return (
    <>
      <ScrollButton />
      <HeaderClient />
      {children}
      <FooterClient />
    </>
  );
}

export default PageClient;
