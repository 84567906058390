import React, { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import cn from "classnames";
import styles from "./header.module.css";
import useWindowDimensions from "../../utils/useWindowDimensions";
import {
  aboutPattern,
  contactPattern,
  getAboutRoute,
  getContactRoute,
  getHomeRoute,
  getLayoutRoute,
  homePattern,
  policyPattern,
} from "../../routes";
import { useWindowScrollPositions } from "../../utils/useWindowScrollPositions";
import logo from "../../assets/images/logo.png";

function Header() {
  const [show, setShow] = useState(false);
  const [showTour, setShowTour] = useState(false);
  const { width, height } = useWindowDimensions();
  const { scrollX, scrollY } = useWindowScrollPositions();

  const { pathname } = useLocation();
  const { id } = useParams();

  useEffect(() => {
    if (
      pathname === homePattern ||
      pathname === aboutPattern ||
      pathname === contactPattern ||
      pathname === policyPattern
    ) {
      if (width > 920) {
        setShow(true);
      } else {
        setShow(false);
      }
    } else {
      setShow(false);
    }
  }, [id, pathname, width]);

  useEffect(() => {
    if (pathname === `/tour/${id}`) {
      setShowTour(true);
    } else {
      setShowTour(false);
    }
  }, [id, pathname]);

  return (
    <div
      className={cn(styles["navbar"])}
      style={
        scrollY > 100 && width > 920
          ? {
              borderBottom: " 1px solid var(--grey-three)",
              backgroundColor: "var(--white)",
              //padding: "0 150px",
            }
          : scrollY > 100 && width < 919
          ? {
              borderBottom: " 1px solid var(--grey-three)",
              backgroundColor: "var(--white)",
            }
          : null
      }
      data-aos="fade-down"
      data-aos-delay="200"
    >
      <div className={cn(styles["row"])}>
        <Link to={getHomeRoute()}>
          <button className={cn(styles["brand"])}>
            <img src={logo} alt="logo"/>
          </button>
        </Link>
        <button
          className={cn(styles["menu-btn"])}
          onClick={() => setShow(!show)}
        >
          <svg
            t="1583388234559"
            className={cn(styles["icon"])}
            viewBox="0 0 1024 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            p-id="2279"
            width="30"
            height="30"
          >
            <path
              d="M128 213.34016l768 0q17.67424 0 30.16704 12.4928t12.4928 30.16704-12.4928 30.16704-30.16704 12.4928l-768 0q-17.67424 0-30.16704-12.4928t-12.4928-30.16704 12.4928-30.16704 30.16704-12.4928zM128 725.34016l768 0q17.67424 0 30.16704 12.4928t12.4928 30.16704-12.4928 30.16704-30.16704 12.4928l-768 0q-17.67424 0-30.16704-12.4928t-12.4928-30.16704 12.4928-30.16704 30.16704-12.4928zM128 469.34016l768 0q17.67424 0 30.16704 12.4928t12.4928 30.16704-12.4928 30.16704-30.16704 12.4928l-768 0q-17.67424 0-30.16704-12.4928t-12.4928-30.16704 12.4928-30.16704 30.16704-12.4928z"
              p-id="2280"
              fill="#40403F"
            ></path>
          </svg>
        </button>
      </div>
      <div className={cn(styles["tabs"])}>
        {show ? (
          <>
            <Link to={getHomeRoute()}>
              <button
                className={cn(styles["tab"])}
                style={
                  pathname === homePattern
                    ? { color: "var(--text-color)" }
                    : null
                }
              >
                HOME
              </button>
            </Link>
            {/* <Link to={getAboutRoute()}>
              <button
                className={cn(styles["tab"])}
                style={
                  pathname === aboutPattern
                    ? { color: "var(--text-color)" }
                    : null
                }
              >
                ABOUT
              </button>
            </Link> */}
            <Link to={getContactRoute()}>
              <button
                className={cn(styles["tab"])}
                style={
                  pathname === contactPattern
                    ? { color: "var(--text-color)" }
                    : null
                }
              >
                ENQUIRY
              </button>
            </Link>
          </>
        ) : null}
        {showTour ? (
          <>
            <Link to={getLayoutRoute()}>
              <button className={cn(styles["tab"])}>
                Choose Another Layout
              </button>
            </Link>
          </>
        ) : null}
      </div>
    </div>
  );
}

export default Header;
